'use client'

import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import { httpRaw } from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { formatDate } from '@/utils/helpers';

function CreateWalletVirtualAccountModal({
  children
}: {
  children: React.ReactNode
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [tab, setTab] = useState<"individual" | "business">("individual")

  const [individualPayload, setIndividualPayload] = useState({
    bank: "",
    bvn: "",
    lastname: "",
    firstname: "",
    middlename: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
    address: {
      street: "",
      city: "",
      state: "",
      postal_code: ""
    }
  });

  const [businessPayload, setBusinessPayload] = useState({
    bank: "",
    bvn: "",
    lastname: "",
    firstname: "",
    middlename: "",
    gender: "",
    dob: "",
    email: "",
    phone: "",
    address: {
      street: "",
      city: "",
      state: "",
      postal_code: ""
    },
    business: {
      name: "",
      registration_number: ""
    }
  });

  const [banks, setBanks] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingDatas, setIsFetchingDatas] = useState(true);

  useEffect(() => {

    async function getBanks() {
      setIsFetchingDatas(true)
      try {
        const resp = await httpRaw("https://dev.lucrestack.co/service/bankaccount/ngn/supported-bank");
        console.log("resp banks data:", resp.data)
        if (resp.data && resp.data.length > 0) {
          setIsFetchingDatas(false);
          setBanks(resp.data);
        } else {
          setIsFetchingDatas(false);
          setBanks([]);
        }
      } catch (error) {
        console.log("error banks", error)
        setIsFetchingDatas(false);
        handleResponseError(error, toast);
        setBanks([]);
      }
    }
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function createVirtualAccountIndividual() {
    setIsFetching(true);
    try {
      const resp = await httpRaw("https://dev.lucrestack.co/service/bankaccount/ngn/create-individual-allocation", { ...individualPayload });
      // console.log("resp", resp.data)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      // onClose();
      window.location.reload();
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  async function createVirtualAccountBusiness() {
    setIsFetching(true);
    try {
      const resp = await httpRaw("https://dev.lucrestack.co/service/bankaccount/ngn/create-business-allocation", { ...businessPayload });
      // console.log("resp", resp.data)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      // onClose();
      window.location.reload();
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Create virtual account</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <div className='mb-8 flex gap-5'>
              <button className={classNames('text-[13px]', {
                'text-purple-600 underline font-bold': tab === "individual",
                '': tab === "business"
              })} onClick={() => { setTab("individual") }}>Individual</button>
              <button className={classNames('text-[13px]', {
                'text-purple-600 underline font-bold': tab === "business",
                '': tab === "business"
              })} onClick={() => { setTab("business") }}>Business</button>
            </div>

            {
              tab === "individual" ?
                <div className='mb-3'>
                  {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{curreencyFormat(data.available_balance)}</div> */}

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>First name</div>
                    <input type='text' name='firstname' value={individualPayload.firstname} onChange={(e) => setIndividualPayload((p) => ({ ...p, firstname: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Last name</div>
                    <input type='text' name='lastname' value={individualPayload.lastname} onChange={(e) => setIndividualPayload((p) => ({ ...p, lastname: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Middle name</div>
                    <input type='text' name='middlename' value={individualPayload.middlename} onChange={(e) => setIndividualPayload((p) => ({ ...p, middlename: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Gender</div>
                    <select value={individualPayload.gender} onChange={(e) => setIndividualPayload((p) => ({ ...p, gender: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching || isFetchingDatas}>
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Date of birth</div>
                    <input type='date' onChange={(e) => setIndividualPayload((p) => ({ ...p, dob: formatDate(e.target.value, false) }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Bank</div>
                    <select value={individualPayload.bank} onChange={(e) => setIndividualPayload((p) => ({ ...p, bank: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching || isFetchingDatas}>
                      <option value="">Select</option>
                      {
                        banks.length > 0 && banks.map((b, i) => (
                          <option value={b.bank} key={i}>{b.bank}</option>
                        ))
                      }
                    </select>
                  </div>
                  
                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Email</div>
                    <input type='email' name='email' value={individualPayload.email} onChange={(e) => setIndividualPayload((p) => ({ ...p, email: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>
                  
                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Phone</div>
                    <input type='text' value={individualPayload.phone} onChange={(e) => setIndividualPayload((p) => ({ ...p, phone: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>BVN</div>
                    <input type='text' value={individualPayload.bvn} onChange={(e) => setIndividualPayload((p) => ({ ...p, bvn: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Address Information</div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>State</div>
                    <input type='text' value={individualPayload.address.state} onChange={(e) => setIndividualPayload((p) => ({ ...p, address: { ...p.address, state: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>City</div>
                    <input type='text' value={individualPayload.address.city} onChange={(e) => setIndividualPayload((p) => ({ ...p, address: { ...p.address, city: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Street</div>
                    <input type='text' value={individualPayload.address.street} onChange={(e) => setIndividualPayload((p) => ({ ...p, address: { ...p.address, street: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-10'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Postal code</div>
                    <input type='text' value={individualPayload.address.postal_code} onChange={(e) => setIndividualPayload((p) => ({ ...p, address: { ...p.address, postal_code: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => createVirtualAccountIndividual()} disabled={isFetching}>
                    {
                      isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                    }
                  </button>
                </div> :
                <div className='mb-3'>
                  {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{curreencyFormat(data.available_balance)}</div> */}

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>First name</div>
                    <input type='text' value={businessPayload.firstname} onChange={(e) => setBusinessPayload((p) => ({ ...p, firstname: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Last name</div>
                    <input type='text' value={businessPayload.lastname} onChange={(e) => setBusinessPayload((p) => ({ ...p, lastname: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Middle name</div>
                    <input type='text' value={businessPayload.middlename} onChange={(e) => setBusinessPayload((p) => ({ ...p, middlename: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Gender</div>
                    <select value={businessPayload.gender} onChange={(e) => setBusinessPayload((p) => ({ ...p, gender: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching || isFetchingDatas}>
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Date of birth</div>
                    <input type='date' onChange={(e) => setBusinessPayload((p) => ({ ...p, dob: formatDate(e.target.value, false) }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Bank</div>
                    <select value={businessPayload.bank} onChange={(e) => setBusinessPayload((p) => ({ ...p, bank: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching || isFetchingDatas}>
                      <option value="">Select</option>
                      {
                        banks.length > 0 && banks.map((b, i) => (
                          <option value={b.bank} key={i}>{b.bank}</option>
                        ))
                      }
                    </select>
                  </div>
                  
                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Email</div>
                    <input type='text' value={businessPayload.email} onChange={(e) => setBusinessPayload((p) => ({ ...p, email: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>
                  
                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Phone</div>
                    <input type='text' value={businessPayload.phone} onChange={(e) => setBusinessPayload((p) => ({ ...p, phone: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>BVN</div>
                    <input type='text' value={businessPayload.bvn} onChange={(e) => setBusinessPayload((p) => ({ ...p, bvn: e.target.value }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Address Information</div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>State</div>
                    <input type='text' value={businessPayload.address.state} onChange={(e) => setBusinessPayload((p) => ({ ...p, address: { ...p.address, state: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>City</div>
                    <input type='text' value={businessPayload.address.city} onChange={(e) => setBusinessPayload((p) => ({ ...p, address: { ...p.address, city: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Street</div>
                    <input type='text' value={businessPayload.address.street} onChange={(e) => setBusinessPayload((p) => ({ ...p, address: { ...p.address, street: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Postal code</div>
                    <input type='text' value={businessPayload.address.postal_code} onChange={(e) => setBusinessPayload((p) => ({ ...p, address: { ...p.address, postal_code: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Business Information</div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Business name</div>
                    <input type='text' value={businessPayload.business.name} onChange={(e) => setBusinessPayload((p) => ({ ...p, business: { ...p.business, name: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <div className='mb-10'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Business registration number</div>
                    <input type='text' value={businessPayload.business.registration_number} onChange={(e) => setBusinessPayload((p) => ({ ...p, business: { ...p.business, registration_number: e.target.value } }))} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => createVirtualAccountBusiness()} disabled={isFetching}>
                    {
                      isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                    }
                  </button>
                </div>
            }
          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateWalletVirtualAccountModal