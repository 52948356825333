'use client'

import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import { httpRaw } from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useTheme } from '@/contexts/themeProvider';
import { upperCaseFirst } from '@/utils/helpers';
import CustomSelect from '../CustomSelect';

function AddTransferRecipientModal({
  children,
  tab
}: {
  children: React.ReactNode;
  tab: "external" | "internal"
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [identifier, setIdentifier] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [institutions, setInstitutions] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingDatas, setIsFetchingDatas] = useState(true);

  async function getDatas() {
    try {
      const resp = await Promise.all([
        httpRaw("https://dev.lucrestack.co/wallet/transfer/external/ngn/recipient/institutions")
      ]);
      // console.log("institutions", resp[0].data);
      // console.log("currencies", resp[1].data)
      setInstitutions(resp[0].data);
      setIsFetchingDatas(false);
    } catch (error) {
      console.log(error)
      setIsFetchingDatas(false);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function addRecipientExternal() {
    setIsFetching(true);
    try {
      const resp = await httpRaw("https://dev.lucrestack.co/wallet/transfer/external/ngn/recipient/single/add", { code, number });
      console.log("resp", resp.data)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      window.location.reload();
      // setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  async function addRecipientInternal() {
    setIsFetching(true);
    try {
      const resp = await httpRaw("https://dev.lucrestack.co/wallet/transfer/internal/ngn/recipient/single/add", { identifier });
      console.log("resp", resp.data)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      window.location.reload();
      // setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Add {upperCaseFirst(tab)} Transfer Recipient</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            {
              tab === "external" ?
                <div className='mb-3'>
                  {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{currencyFormat(data.available_balance)}</div> */}
                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px] flex gap-3'>
                      <div>Bank</div>
                      {isFetchingDatas && <div><ImSpinner8 /></div>}
                    </div>
                    <CustomSelect
                      value={code}
                      setValue={setCode}
                      options={institutions}
                      optionsKey={
                        {
                          value: "code",
                          data: ["name"]
                        }
                      }
                      />
                    {/* <select value={code} onChange={(e) => setCode(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} >
                      <option value="">Select bank</option>
                      {
                        institutions.map((u, i) => (
                          <option value={u.code} key={i}>{u.name}</option>
                        ))
                      }
                    </select> */}
                  </div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Account number</div>
                    <input type='text' value={number} onChange={(e) => setNumber(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
                  </div>

                  <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => addRecipientExternal()} disabled={isFetching}>
                    {
                      isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                    }
                  </button>
                </div> :
                <div className='mb-3'>
                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Enter identifier</div>
                    <input type='text' value={identifier} onChange={(e) => setIdentifier(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' placeholder='Phone number or Email or Wallet ID  ' disabled={isFetching} />
                  </div>

                  <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => addRecipientInternal()} disabled={isFetching}>
                    {
                      isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                    }
                  </button>
                </div>
            }
          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddTransferRecipientModal