'use client'

import React, { useEffect, useMemo, useState } from 'react'
import http from '@/lib/http';
import { Skeleton } from '@chakra-ui/react';
import { FaAngleRight } from 'react-icons/fa6'
import AutoGenNormalViewModal from '@/components/modals/AutoGenNormalViewModal';
import FilterForm from '@/components/FilterForm';
import { TbCurrencyNaira } from 'react-icons/tb';
import classNames from 'classnames';

interface IBankAccounts {
    reference_code: string,
    number: string,
    bank: string,
    code: string,
    name: string
}

function BusinessServicesBankAccountClient() {

    const [details, setDetails] = useState<IBankAccounts[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");

    const [activeWallet, setActiveWallet] = useState<"ngn" | "usd" | "gbp" | "eur">("ngn");

    const [paginationInput, setPaginationInput] = useState<any>();
    const filterInputs = {
        number: "",
        reference_code: "",
        order_by: ""
    }
    function setPaginate(resp: any) {
        setPaginationInput({
            counts: resp.counts,
            totalPages: resp.totalPages,
            currentPage: resp.currentPage,
            listPerPage: resp.listPerPage
        });
    }

    async function fetchServices() {
        try {
            const resp = await http(`https://dev.lucrestack.co/service/bankaccount/${activeWallet}/details`, { limit: 10 });
            console.log("resp", resp)
            setDetails(resp.data)
            setPaginate(resp)
            setIsFetching(false);
        } catch (error) {
            console.log(error)
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeWallet]);

    const filteredDetails = useMemo(() => {
        if (details.length === 0) return [];
        //  if(search === "");
        const sorted_ = sort === "date-asc" ?
            details.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
            sort === "date-desc" ?
                details.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
                sort === "z-a" ?
                    details.slice().sort((a: any, b: any) => b.name.toLowerCase() < a.name.toLowerCase() ? -1 : 1) :
                    details.slice().sort((a: any, b: any) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);

        const filtered_ = search.length > 0 ?
            sorted_.filter((e: any) => e.name.toLowerCase().includes(search.toLowerCase())) :
            sorted_;

        return filtered_;

    }, [search, sort, details]);

    return (
        <>
            <div className='overflow-auto max-w-full md:max-w-full mb-10'>
                <div className='text-xs flex text-slate-500 items-center gap-3'>
                    <div>Business</div>
                    <div><FaAngleRight /></div>
                    <div>Services</div>
                    <div><FaAngleRight /></div>
                    <div>Bank Account</div>
                </div>
            </div>

            <div className='mb-10 text-xl'>Bank Account</div>

            <div className='mb-8'>
                <div className='flex items-center gap-3'>
                    <div onClick={() => setActiveWallet("ngn")} className={classNames('flex gap-3 items-center border-2 px-2 py-2 rounded-lg cursor-pointer', {
                        'border-purple-700 dark:border-purple-600': activeWallet === "ngn",
                        'border-slate-200 dark:border-neutral-700': activeWallet !== "ngn"
                    })}>
                        <div className='bg-green-700 text-neutral-100 sm:p-1 p-1'>
                            <TbCurrencyNaira className='w-7 h-7' />
                        </div>
                        <div className='w-full'>
                            <div className='text-[14px]'>
                                NGN
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mb-8 flex gap-5 items-center'>
                <input value={search} type="text" className='px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setSearch(e.target.value)} placeholder='Enter Search Keyword' />
                <div className='flex-1'>
                    <div className='text-xs'>Sort by</div>
                    <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={sort} onChange={(e) => setSort(e.target.value)}>
                        <option value="a-z">A-Z</option>
                        <option value="z-a">Z-A</option>
                        <option value="date-asc">Date (Ascending)</option>
                        <option value="date-desc">Date (Descending)</option>
                    </select>
                </div>
            </div>

            <hr className='mb-6 border-slate-300 dark:border-neutral-700' />

            <FilterForm
                url={`https://dev.lucrestack.co/service/bankaccount/${activeWallet}/details`}
                inputs={filterInputs}
                paginationInput={paginationInput}
                isLoading={isFetching}
                setIsLoading={setIsFetching}
                setState={setDetails}
                setPaginationInput={setPaginationInput}
                inputtedIsSelectTag={{ status: ["Successful", "Pending", "Processing", "Failed"] }}
            />

            <div className='mb-10 w-full'>

                <div className="relative overflow-auto max-h-[500px] flex-1">
                    <table className="min-w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Bank
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Account Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !isFetching ? filteredDetails.map((d, k) => (
                                    <tr key={k} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {d.name}
                                        </th>
                                        <td className="px-6 py-4">
                                            {d.bank}
                                        </td>
                                        <td className="px-6 py-4">
                                            {d.number}
                                        </td>
                                        <td className="px-6 py-4 flex gap-3">
                                            <AutoGenNormalViewModal title='Bank accounts' data={d}><span className='font-bold text-blue-600'>View</span></AutoGenNormalViewModal>
                                        </td>
                                    </tr>
                                )) :
                                    Array.from(Array(5)).map((k, i) => (
                                        <tr key={i} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <Skeleton h={'20px'} />
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    )
}

export default BusinessServicesBankAccountClient 