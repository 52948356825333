'use client'

import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import { httpRaw } from '@/lib/http';
import { BadRequestError, handleResponseError } from '@/utils/errors';
import { useTheme } from '@/contexts/themeProvider';

function AddDocumentModal({
  children
}: {
  children: React.ReactNode
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [docType, setDocType] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  async function addDocument() {
    setIsFetching(true);
    try {
      if (docType.length === 0) throw new BadRequestError("Select document type to continue");
      if (!file) throw new BadRequestError("Select file to upload");

      const formData = new FormData();
      formData.append('file', file!);

      const uploadResp = await httpRaw("https://dev.lucrestack.co/misc/file-upload", formData) ;
      console.log("upload response", uploadResp.data);

      const resp = await httpRaw(docType, { document_file: uploadResp.data });
      // console.log("doc response", resp)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      window.location.reload();
      // setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Upload Document</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>

            <div className='mb-3'>
              {/* <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{currencyFormat(data.available_balance)}</div> */}

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Document type</div>
                <select value={docType} onChange={(e) => setDocType(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching}>
                  <option value="">Select</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/aml">AML policies and procedures</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/directors">Valid ID of Directors</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/licenses">Relevant licenses to carry on a business</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/certificate">Certificate of Incorporation or equivalent evidence of legal business registration</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/memorandum">Memorandum and Articles of Association</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/address">Proof of Address of the company and directors and shareholders</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/shareholders">Incorporation document reflecting the current shareholders and directors of the company</option>
                  <option value="https://dev.lucrestack.co/provider/document/upload/source-of-funds">Signed/certified statement regarding the company’s source of funds</option>
                </select>
              </div>

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>File</div>
                <input type='file' onChange={handleFileChange} accept='image/*'
                  className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>

              <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => addDocument()} disabled={isFetching || docType.length === 0}>
                {
                  isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                }
              </button>
            </div>
          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddDocumentModal