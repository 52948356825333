'use client'

import CreateWalletModal from '@/components/modals/CreateWalletModal';
import TransferWalletModal from '@/components/modals/TransferWalletModal';
import TopupWalletModal from '@/components/modals/TopupWalletModal';
import { httpRaw } from '@/lib/http';
import { currencyFormat } from '@/utils/helpers';
import { Skeleton } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { FaAngleRight } from 'react-icons/fa6'
import { TbCurrencyNaira } from "react-icons/tb";
import AutoGenNormalViewModal from '@/components/modals/AutoGenNormalViewModal';
import CreateWalletVirtualAccountModal from '@/components/modals/CreateWalletVirtualAccountModal';
import { Link } from 'react-router-dom';
// import { PiDotsThreeOutlineBold, PiUsersThreeBold } from 'react-icons/pi'

function BusinessWalletClient() {

    const [isFetching, setIsFetching] = useState(true);
    const [wallets, setWallets] = useState<any[]>([]);
    const [show, setShow] = useState("");

    async function fetchWallets() {
        try {
            const resp = await httpRaw("https://dev.lucrestack.co/wallet/manager/details");
            // console.log("resp", resp.data)
            setWallets(resp.data)
            setIsFetching(false);
        } catch (error) {
            console.log(error)
            setIsFetching(false);
        }
    }

    useEffect(() => {
        fetchWallets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filtered = useMemo(() => {
        if (wallets.length === 0) return [];

        const filtered_ = show.length > 0 ?
            wallets.filter((e: any) => `${e.type}`.toLowerCase().includes(show.toLowerCase())) :
            wallets;

        return filtered_;

    }, [wallets, show]);

    function walletDebit(wallet_id: string, remark: string, amount: string) {
        const index = wallets.findIndex(wallet => wallet.wallet_id === wallet_id);
        if (index !== -1) {
            const updatedWallet = [...wallets];
            updatedWallet[index].available_balance = `${parseFloat(updatedWallet[index].available_balance) - parseFloat(amount)}`;
            setWallets(updatedWallet);
        }
    };

    // function walletUpdate(wallet_id: string, active: string, locked_balance: string) {
    //     const index = wallets.findIndex(wallet => wallet.wallet_id === wallet_id);
    //     if (index !== -1) {
    //         const updatedWallet = [...wallets];
    //         updatedWallet[index].locked_balance = locked_balance;
    //         setWallets(updatedWallet);
    //     }
    // };

    return (
        <>
            <div className='text-sm text-slate-500 flex items-center gap-3 mb-10'>
                <div>Business</div>
                <div><FaAngleRight /></div>
                <div>wallet</div>
            </div>

            <div className='mb-5 text-xl'>Wallet</div>

            <div className='mb-8 flex gap-5 items-center justify-between'>
                {/* <input type="text" className='px-3 py-3 sm:w-[400px] flex-1 sm:flex-none text-sm border-2 border-neutral-600 dark:bg-neutral-700' placeholder='Enter Search Keyword' /> */}
                <div>
                    <div className='text-xs'>Show</div>
                    <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={show} onChange={(e) => setShow(e.target.value)}>
                        <option value="">All</option>
                        <option value="credit">Credit</option>
                        <option value="general">General</option>
                    </select>
                </div>

                <CreateWalletModal>
                    <div className='text-sm text-neutral-100 bg-purple-800 px-2 py-2'>Create wallet</div>
                </CreateWalletModal>
            </div>

            <div className='mb-10'>
                <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 sm:gap-6 gap-5'>

                    {
                        !isFetching ? filtered.map((w, i) => (
                            <div key={i} className='sm:px-5 px-3 py-4 sm:py-5 bg-slate-50 dark:bg-neutral-800 rounded-lg shadow-md'>
                                <div className='flex gap-3 items-start'>
                                    <div className='bg-green-700 text-neutral-100 sm:p-3 p-1'>
                                        <TbCurrencyNaira className='w-7 h-7' />
                                    </div>
                                    <div className='w-full'>
                                        <div className='mb-3'>
                                            <div className='flex gap-1 text-xs'>
                                                <div>Type:</div>
                                                <div className='underline text-orange-600'>{w.type}</div>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className='text-[10px]'>
                                                Available balance
                                            </div>
                                            <div>
                                                {currencyFormat(w.available_balance)}
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className='text-[10px]'>
                                                Pending balance
                                            </div>
                                            <div>
                                                {currencyFormat(w.pending_balance)}
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className='text-[10px]'>
                                                Locked balance
                                            </div>
                                            <div>
                                                {currencyFormat(w.locked_balance)}
                                            </div>
                                        </div>
                                        <div className='mb-8 flex gap-5'>
                                            <div>
                                                <div className='text-[10px]'>
                                                    Total inflow
                                                </div>
                                                <div>
                                                    {w.inflow.total}
                                                </div>
                                            </div>
                                            <div>
                                                <div className='text-[10px]'>
                                                    Total outflow
                                                </div>
                                                <div>
                                                    {w.outflow.total}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className='text-xl text-opacity-80 '></div>
                                            <div className='flex gap-2 font-bold mb-2'>
                                                <TopupWalletModal data={w}>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>Fund</div>
                                                    </div>
                                                </TopupWalletModal>
                                                <TransferWalletModal data={w} walletDebit={walletDebit}>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>Transfer</div>
                                                    </div>
                                                </TransferWalletModal>
                                                {/* <UpdateWalletModal data={w} walletUpdate={walletUpdate}>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>Update</div>
                                                    </div>
                                                </UpdateWalletModal> */}
                                                {/* <TopupWalletModal data={w} walletTopup={walletTopup} business_id={user!.business!.id}>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>Withdraw earnings</div>
                                                    </div>
                                                </TopupWalletModal> */}
                                            </div>
                                            <div className='mb-3 font-bold'>
                                                <Link to={`/d/wallet/transactions?wallet=${w.wallet_id}`} className='inline-block'>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>View Transactions</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='flex gap-2 font-bold mb-3'>
                                                <AutoGenNormalViewModal title='Virtual accounts' data={w.virtual_funding_account}>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>View virtual accounts</div>
                                                    </div>
                                                </AutoGenNormalViewModal>
                                            </div>
                                            <div className='flex gap-2 font-bold'>
                                                <CreateWalletVirtualAccountModal>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>create virtual accounts</div>
                                                    </div>
                                                </CreateWalletVirtualAccountModal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) :
                            Array.from(Array(6)).map((k) => (
                                <Skeleton h={'130px'} key={k} />
                            ))
                    }

                </div>
            </div>
        </>
    )
}

export default BusinessWalletClient